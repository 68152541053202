<template>
    <div>
        <wxlogin
                appid="wxd9eb1abb57cad81f"
                :self_redirect="'true'"
                :scope="'snsapi_login'"
                :theme="'black'"
                :redirect_uri='encodeURIComponent("https://www.baigongbao.com/test/wx_qrcode")'
                :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZxxx='"
                rel="external nofollow">
        </wxlogin>
    </div>
</template>

<script>
    import wxlogin from 'vue-wxlogin';
    export default {
        name: "registered",
        components: {
            wxlogin
        }
    }
</script>

<style scoped>

</style>
